import React,{useState,useEffect} from "react";
import '../css/signUpPage.css';
import { useNavigate } from "react-router-dom";
import AuthService  from '../services/auth.service';
import { useFormInputValidation } from "react-form-input-validation";
import CreativeLogoComponent from '../components/creativeLogoComponent';


function SignUpPage(){
    const navigate = useNavigate();
    const [passwordType, setPasswordType] = useState("password");
    const [passwordCnfType, setCnfPasswordType] = useState("password");

    const [fields, errors, form] = useFormInputValidation({
        email_address: "",
        phone_number:"",
        password: "",
        password_confirmation: "",
    }, {
        email_address: "required|email",
        phone_number: "required|numeric|digits:10",
        password: "required|password_validation",
        password_confirmation: "required|same:password|password_validation",
    });
    const togglePassword =()=>{
        if(passwordType==="password")
        {
        setPasswordType("text")
        return;
        }
        setPasswordType("password")
    }
    const toggleCnfPassword =()=>{
        if(passwordCnfType==="password")
        {
        setCnfPasswordType("text")
        return;
        }
        setCnfPasswordType("password")
    }

    const onSubmit = async (event) => {
        const isValid = await form.validate(event);
        if (isValid) {
        let payload={"email":fields.email_address,"phoneno":fields.phone_number,"password":fields.password}
          async function register() {
           let data= await AuthService.register(payload);
           if(data){
            navigate(process.env.PUBLIC_URL+"/verify-otp");
           }else{
            return false;
           }
           }
           register(); 
        }
      }
      useEffect(() => {
        if(AuthService.isLoggedInVendor()){
            navigate(process.env.PUBLIC_URL+"/vendor-dashboard");
        }
        form.registerAsync("password_validation", function (
            str,
            attribute,
            req,
            passes
          ) {
              var specials = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g;
              if (!specials.test(str))
                passes(false, "Password should have minimum eight characters, at least one letter, one number and one special character");
              else passes();
          });
      }, []);
    return(
        <div className="auth-wrapper">
        <div className="mxw-container">
            <CreativeLogoComponent/>
            <div className="auth-form-wpr">
                <div className="form-w">
                    <div className="form-card">
                        <div className="greeting">
                            <h2>Welcome</h2>
                            <h3>Sign Up to Network 18</h3>
                        </div>
                        <form id="registerForm" noValidate autoComplete="off" onSubmit={onSubmit}>
                            <div className="fg">
                                <input  id="emailPhoneNumber" placeholder="Email" className="fc" type="email" name="email_address" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.email_address} />
                                <label className="error"> {errors.email_address ? errors.email_address : ""} </label>
                            </div>
                            <div className="fg">
                                <input id="phoneNumber" placeholder="Phone Number" className="fc" type="tel" name="phone_number" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.phone_number} />
                                <label className="error"> {errors.phone_number ? errors.phone_number : ""} </label>
                            </div>
                            <div className="fg">
                                <div className="pwd-wpr">
                                    <input type={passwordType} id="password" placeholder="Password" className="fc" name="password" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.password} data-attribute-name="password" data-async  />
                                    <span className="pi-wpr" ><i onClick={togglePassword} className="fi fi-visibility-black-o"></i></span>
                                </div>
                                <label className="error"> {errors.password ? errors.password : ""} </label>
                            </div>
                            <div className="fg">
                                <div className="pwd-wpr">
                                <input type={passwordCnfType} id="password_confirmation" placeholder="Confirm Password" className="fc" name="password_confirmation" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.password_confirmation} data-attribute-name="password_confirmation" data-async />
                                    <span className="pi-wpr" ><i onClick={toggleCnfPassword} className="fi fi-visibility-black-o"></i></span>
                                </div>
                                <label className="error"> {errors.password_confirmation ? errors.password_confirmation : ""} </label>
                            </div>
                            <div className="fg form-action">
                                <button type="submit" className="submit-btn">Sign Up</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default SignUpPage;
