import React,{useState,useEffect} from "react";
import '../css/file18.css';
import VendorService  from '../services/vendor.service';
import { ColorRing } from 'react-loader-spinner';

function VendorContractCopy(){
    const [loading, setLoading] = useState(true);
    const [ vendor, setVendor ] = useState([]);
    const initialState = { alt: "", src: "",file_status:"", comments:""};    
    const [{ contract_name, contract_src, contract_status,contract_comments }, setContractCopyPreview] = useState(initialState);
    const [{ legal_contract_name, legal_contract_src, legal_contract_status,legal_contract_comments }, setLegalContractCopyPreview] = useState(initialState);
    const [error_contract_copy, setErrorContractCopy] = useState({});
    const [file_contract_copy, setContractCopy] = useState({});
    const [contract_preview, setContractPreview] = useState({});
    const [contract_exists, checkContract] = useState({});
    const [btn_disable, setBtnDisable] = useState(false);
    const MINUTE_MS = 10000;//10 sec;

   const setPreview= (vendor_documents) =>{ 
    if (vendor_documents) {
        vendor_documents.map(function(val, index){
            if(val.document_type==="contract copy"){
                setContractCopyPreview({
                    contract_src:val.document_path,
                    contract_name:val.document_name,
                    contract_status:val.status,
                    contract_comments:(val.comments)? val.comments :"" 
                });
                checkContract(true);
            }
            if(val.document_type==="contract copy legal"){
                setLegalContractCopyPreview({
                    legal_contract_src:val.document_path,
                    legal_contract_name:val.document_name,
                    legal_contract_status:val.status,
                    legal_contract_comments:(val.comments)? val.comments :"" 
                });
            }
        })
    }
   }

   const onSubmit = async (event) => {
        event.preventDefault();
        const isValid= await FormValidation(event);
        if (isValid) {
            setBtnDisable(true);
            const formData = new FormData();
            formData.append('file_contract_copy', file_contract_copy);
            async function setContractCopyVendor() 
            {
                if(contract_exists == true){
                    await VendorService.updateContractCopyVendor(formData,true);
                    let data = await VendorService.getCurrentVendor();
                    setVendor(data);
                } else {
                    await VendorService.uploadContractCopyVendor(formData,true);
                    let data = await VendorService.getCurrentVendor();
                    setVendor(data);
                    checkContract(true);
                }
            }
            setContractCopyVendor(); 
            setTimeout(() => {
                setBtnDisable(false);
            }, MINUTE_MS);
        }
    }

   const ImageValidation =(file)=>{
        var filePath = file.name;
        // Allowing file type
        var allowedExtensions =  /(\.jpg|\.jpeg|\.pdf)$/i;
        if (!allowedExtensions.exec(filePath)) {
            return {status:false,"msg":"Invalid file type"};
        }else if (file.size > 25000000) { // Size of document should be between 4KB and 5MB.
            return {status:false,"msg":"Size of contract copy should be less than 25MB."};
        } 
        return {status:true,"msg":""};
    
    }
   const FormValidation =(e)=>{
        let isValid= true;
        if (typeof file_contract_copy.name == 'undefined') {
            setErrorContractCopy({status:false,"msg":"Please upload contract copy"});
            isValid=false;
        }
        return isValid;
    }

    useEffect(() => {
        async function getVendor() {
            let data = await VendorService.getCurrentVendor();
            setVendor(data);
            setPreview(data.vendor_documents);
        }
        getVendor();
        setLoading(false); 
    }, []);

    const handleChangeContractCopy = (event) => {
        const valid=ImageValidation(event.target.files[0]);
        if(valid.status){
            setContractCopy(event.target.files[0]);
            setContractPreview(true);
        }else{
            event.target.value = null;
            setContractCopy('');
            setContractPreview(false);
        }
        setErrorContractCopy(valid);
    }

    const deleteContractCopy = (event) => {
        event.target.value = null;
        document.querySelector(".upload_contract_name").innerText = '';
        setContractPreview(false);
    }

    return(
        <form className="contributor-form" onSubmit={onSubmit}>
            <div className="section-cc">
                <h3 className="section-title">Accept Contract Copy</h3>
                <div className="contract-wrapper">
                    <div className="col-left">
                        <div className="label">Download the Contract Copy</div>
                        <div className="download-file">
                            <div className="icon-holder"><i className="fi fi-file-copy-black"></i></div>
                            <div className="upload_file_name">{legal_contract_name}</div>
                            <a href={legal_contract_src} download className="download-icon"><i className="fi fi-file-download"></i></a>
                        </div>
                    </div>
                    <div className="col-right">
                    {
                        contract_name && (
                            <div className="upload-contract-copy">
                                <label htmlFor="">Signed contract copy pdf format. Maximum file size (25 MB)</label>
                                <div className="download-file">
                                    <div className="icon-holder"><i className="fi fi-file-copy-black"></i></div>
                                    <div className="upload_file_name">{contract_name}</div>
                                    <a href={contract_src} download className="download-icon"><i className="fi fi-file-download"></i></a>
                                </div>
                            </div>
                        )
                    }
                        <div className="upload-contract-copy">
                            <label htmlFor="">Upload the Signed contract copy pdf format. Maximum file size (25 MB)</label>
                            <div className="upload-cc-wrapper">
                                <input type="file" id="upload_file_cc"  name="file_contract_copy"  onChange={handleChangeContractCopy}  accept=".pdf" className="file-input-hide"/>
                                <label htmlFor="upload_file_cc" className="drop-files">
                                    <div className="icon-holder">
                                        <i className="fi fi-upload-documnet"></i>
                                    </div>
                                    <div className="text">Drop files <span className="text-blue">browse</span> through your machine.</div>                                    
                                </label>
                                <label className="error"> {error_contract_copy ? error_contract_copy.msg : ""} </label>
                                {
                                    contract_preview === true && (
                                        <div id="temp_upload_div" className="temp-upload-file" >
                                            <div className="icon-holder"><i className="fi fi-file-copy-black"></i></div>
                                            <div id="upload_contract_name" className="upload_contract_name">{file_contract_copy.name}</div>
                                            <div id="remove_file" className="remove-icon" onClick={deleteContractCopy}><i className="fi fi-close-black"></i></div>                                            
                                        </div>
                                        
                                    )
                                }                                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-action">
                {(btn_disable) ? ( <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={['#2A426E','#2A426E','#2A426E','#2A426E','#2A426E']}
                />) : (<button type="submit" className="submit-btn btn-solid">Submit</button>)}
            </div>
        </form>
    )
}
export default VendorContractCopy;
