import React, { useState, useEffect } from "react";
import '../../css/event/signUp.css';
import Sidebar from '../../components/event/sidebarComponent';
import { useNavigate, useParams } from "react-router-dom";
import AdminService from '../../services/event/admin.service';
import AuthService from "../../services/event/auth.service";
import { useFormInputValidation } from "react-form-input-validation";
import { RotatingTriangles } from 'react-loader-spinner';
import 'react-responsive-modal/styles.css';
import { ColorRing } from 'react-loader-spinner'
import { format } from 'date-fns'

function UserEdit(props) {
    const {id} = useParams();
    const initialState = { alt: "", src: "" };
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [btn_disable, setBtnDisable] = useState(false);
    const MINUTE_MS = 1000;//10 sec 
    const [userEditResult, setUserEditResult] = useState([]);
    const fetchData = async () => {
        return await AdminService.getUser(id);
    }

    const [fields, errors, form] = useFormInputValidation({
        full_name: "",
        designation:"",
        contact_number:"",
        company_name:"",
        email:"",
        }, {
        full_name: "required",
        designation:"required",
        company_name:"required",
        email: "required|email",
        contact_number: "required|numeric|digits:10",
    });
    
    const onSubmit = async (event) => {
        const isValid = await form.validate(event);
        const formData = new FormData();
        // var dates = format(new Date(fields.event_date), 'd MMMM yyyy');
        if (isValid) {
            setBtnDisable(true);
            formData.append('full_name', fields.full_name);
            formData.append('designation', fields.designation);
            formData.append('contact_number', fields.contact_number);
            formData.append('company_name', fields.company_name);
            formData.append('email', fields.email);
            formData.append('AdminId', id);
            // formData.append('password', fields.password);
            // formData.append('type', "Admin");
           
          async function saveBasicDetails() {
           
                let data = await AdminService.setUserDetails(formData);
                if(!data){
                   
                    return false;
                }
            navigate('/user-list');
            setTimeout(() => {
                setBtnDisable(false);
                // props.setToggleStateClid(2);
            }, MINUTE_MS);
           }
           saveBasicDetails(); 
        }
      }
      useEffect(() => {
        if (!AuthService.isLoggedInAdmin()) {
            navigate(process.env.PUBLIC_URL + "/event-super-admin-login");
        }
        const fetchDataAndSetState = async () => {
            const result = await fetchData();
            setUserEditResult(result || []);
            if(result){
                fields.full_name=result.fullname;
                fields.designation=result.designation;
                fields.company_name=result.company_name;
                fields.contact_number=result.contact_number;
                fields.email=result.email;
            }
            
             
        }
        fetchDataAndSetState();
    }, []);
    return (
        (loading) ? (<RotatingTriangles
            height="100"
            width="100"
            color="#fff"
            ariaLabel="rotating-triangels-loading"
            wrapperStyle={{}}
            wrapperClass="triangle-loading"
            visible={loading}
          />) : userEditResult && (
       
        <div className="page-wrapper">
        <Sidebar sidebarFor="Super-Admin" />

        <div className="content-wrapper">
            <div className="overlay" id="overlay"></div>
            <div className="page-content page-contributor contributor-basic-details">
                <div className="page-header">
                    <h4>User Edit</h4>
                </div>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="off" onSubmit={onSubmit} className="signupform" id="registerform">
                            <div className="add-event-form form-regular">
                                <div className="form-cap-card">                                    
                                    <div className="formGroup">
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">FullName <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <input  id="full_name" placeholder="FullName" className="input-control" type="text" name="full_name" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.full_name} />
                                                <label className="errors"> {errors.full_name ? errors.full_name : ""} </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Designation <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <input  id="designation" placeholder="Designation" className="input-control" type="text" name="designation" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.venue} />
                                                <label className="errors"> {errors.designation ? errors.designation : ""} </label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="formGroup">
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Contact Number <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <input  id="contact_number" placeholder="Contact Number" className="input-control" type="text" name="contact_number" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.contact_number} />
                                                <label className="errors"> {errors.contact_number ? errors.contact_number : ""} </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Company Name <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <input type="text" name="company_name" placeholder="Company Name" className="input-control" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent}/>
                                                   
                                                <label className="errors"> {errors.company_name ? errors.company_name : ""} </label>
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div className="formGroup">
                                    <div className="col-6">
                                            <label htmlFor="" className="input-label">Email <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <input type="text" name="email" placeholder="Email" className="input-control" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent}/>
                                                   
                                                <label className="errors"> {errors.email ? errors.email : ""} </label>
                                            </div>
                                        </div>
                                        
                                    </div>
                                   
                                    </div>
                                    </div>
                                    
                            <div className="fg form-action" style={{ display: 'flex', justifyContent: 'center' }}>

                            {(btn_disable) ? (<ColorRing
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={['#2A426E', '#2A426E', '#2A426E', '#2A426E', '#2A426E']}
                        />) : (<button type="submit" className="submit-btn">Update User</button>)}
                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
          )
    )
     }
export default UserEdit;
