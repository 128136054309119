import React,{useState,useEffect} from "react";
import { useNavigate,Link } from "react-router-dom";
import '../css/signUpPage.css';
import AuthService  from '../services/auth.service';
import OtpInput from 'react-otp-input';
import CreativeLogoComponent from '../components/creativeLogoComponent';

function VerifyOtpPage(){
    const [ vendor_email, setVendorEmail ] = useState("");
    const [otp, setOtp] = useState('');
    const [error, setError] = useState({});
    const navigate = useNavigate();
    const onSubmit = async (event) => {
        event.preventDefault();
        const isValid = await FormValidation(event);
        if (isValid) {
            let payload={"email":vendor_email,"otp":otp}
            async function otpVerification() {
            let data= await AuthService.otpVerification(payload);
            if(data){
                localStorage.removeItem("vendor_email")
                navigate(process.env.PUBLIC_URL+"/vendor-login");
            }
           }
           otpVerification(); 
        }
      }
      const onClick = async (event) => {
        let payload={email:vendor_email}
          async function resendOtp() {
            await AuthService.resendOtp(payload);
           }
           resendOtp(); 
      }
      const FormValidation =(e)=>{
        if (otp === '' || otp.length !== 6) {
            setError({status:false,"msg":"Please enter OTP"});
            return false;
        }
        setError({status:true,"msg":""});
        return true;
      }
      useEffect(() => {
        async function getVendorEmail() {
         let email=localStorage.getItem('vendor_email') ;
            if(email){
                setVendorEmail(email);
            }else{
                navigate(process.env.PUBLIC_URL+"/sign-up");
            }
        }
        getVendorEmail(); 
       }, []);
    return(
        <div className="page-otp-verify">
        <div className="auth-wrapper">
        <div className="mxw-container">
            <CreativeLogoComponent/>
            <div className="auth-form-wpr">
                <div className="form-w">
                    <div className="form-card">
                        <div className="otp-fr-text">
                            <h2>Please enter the verification OTP sent to <br /> <span id="emailID">{vendor_email}.</span> <Link to={`${process.env.PUBLIC_URL}/sign-up`}>Change</Link></h2>
                        </div>
                        <form id="verifyOtpForm" method="post" noValidate autoComplete="off" onSubmit={onSubmit} >
                            <div className="fg">
                                <div className="otpField">
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} style={{width:"60px"}}  className="input" />}
                                    />
                                </div> 
                                <label className="error"> {error ? error.msg : ""} </label>   
                            </div>                            
                            <div className="fg form-action">
                                <button type="submit" className="submit-btn" >VERIFY</button>
                            </div>
                            <div className="resend-wpr">
                                <p>Not received your code? <span className="resend-text" onClick={onClick}>Resend code</span></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    )}
export default VerifyOtpPage;
