import React from "react";
import '../../css/file18.css';
import Sidebar from '../../components/sidebarComponent';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AdminService from '../../services/admin.service';
import AuthService from '../../services/auth.service';
import { RotatingTriangles } from 'react-loader-spinner';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { ColorRing } from 'react-loader-spinner'

function AdminReportersList2() {
  const navigate = useNavigate();
  const params = useParams();
  const [btn_disable, setBtnDisable] = useState(false);
  const MINUTE_MS = 2000;//5 sec 
  const [modalFilename, setmodalFilename] = useState("");
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState([]);
  const [reasonSelection, setReasonSelection] = useState({});
  const [errors, setErrors] = useState({});
  const [errorsinput, setErrorsinput] = useState({});
  const [toggleInput, settoggleInput] = useState({});
  const [toggleValues, setToggleValues] = useState({});
  const [inputValues, setInputValues] = useState({}); // State for input values

  const hardcodedReasons = {
    'Quality poor': 'Quality poor',
    'Offensive content': 'Offensive content',
    'Image not appropriate': 'Image not appropriate',
  };

  const handleDownload = (imageUrl) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        const fileExtension = imageUrl.split(".").pop().toLowerCase();
        const timestamp = Date.now(); // Unique timestamp
        const filename = `image_${timestamp}.${fileExtension}`; // Unique filename
        a.download = filename;

        a.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  const viewPDF = (data, type) => {
    let win = window.open("", "_blank", "titlebar=yes,width=600,height=600");

    if (win) {
      const documentContent = `<!DOCTYPE html>
        <html>
          <head>
            <title>PDF in HTML</title>
          </head>
          <body>
            <iframe src="https://docs.google.com/gview?url=${data}&embedded=true" 
              style="width:600px; height:500px;" frameborder="0"></iframe>
          </body>
        </html>`;

      win.document.write(documentContent);

      // Close the window and reload the page when the window loses focus
      win.addEventListener("blur", () => {
        win.close();
        win = null;
        // window.location.reload();
      });
    }
  };

  const onOpenModal = (filename) => {
    setmodalFilename(filename);
    setOpen(true);

  };
  const onCloseModal = () => {
    setOpen(false);
    setmodalFilename("");
  };
  const [reporters, setReporters] = useState([]);
  const [loading, setLoading] = useState(true);
  const id = params.id;

 const gstDeclarationToggler = (e, document_type) => {
    const isChecked = e.target.checked;
    const isDisabled = isChecked.disabled;

    // console.log('Toggler Status:' + document_type, isChecked);
    const updatedPreview = preview.map((doc) => {
      if (doc.document_type === document_type) {
        return {
          ...doc,
          status: e.target.checked ? "Accepted" : "Rejected"
        };
      }
      return doc;
    });

    

    setPreview(updatedPreview);
    settoggleInput(updatedPreview);

    // Update the toggleValues state based on the checkbox status
    setToggleValues(prevToggleValues => ({
      ...prevToggleValues,
      [document_type]: isChecked
    }));

    // Update the reasonSelection state if the checkbox is unchecked
    if (!isChecked) {
      setReasonSelection((prevReasonSelection) => ({
        ...prevReasonSelection,
        [document_type]: '',
      }));
    }

    // Update the inputValues state if the checkbox is checked

    if (isChecked && isDisabled) {
      setReasonSelection(prevState => ({
        ...prevState,
        [document_type]: ''
      }));

      setInputValues(prevState => ({
        ...prevState,
        [document_type]: ''
      }));
    }

  };


 const fetchData = async () => {
    const result = await AdminService.getVendorDetails(id);
    if (result && result.length > 0) {
      setPreview(result[0].vendor_documents);
    }
    // setPreview(result[0].vendor_documents);
    const initialCheckedValues = {};
    const initialReasonSelection = {};
    const initialInputValues = {};
    if (result && result.length > 0) {

    result[0].vendor_documents.forEach((document) => {
      const isChecked = document.status === 'Accepted';
      initialCheckedValues[document.document_type] = isChecked;

      if (isChecked) {
        initialReasonSelection[document.document_type] = ''; // Set the initial value to an empty string for accepted documents
      } 
      else if(!isChecked && document.status === 'Processing'){
        // initialReasonSelection[document.document_type] = document.comments in hardcodedReasons ? document.comments : '';
        initialReasonSelection[document.document_type] = document.comments in hardcodedReasons ? document.comments : '';

      }
      else {
        // initialReasonSelection[document.document_type] = document.comments in hardcodedReasons ? document.comments : '';
        initialReasonSelection[document.document_type] = document.comments in hardcodedReasons ? document.comments : 'Others';

      }
      initialInputValues[document.document_type] = !hardcodedReasons.hasOwnProperty(document.comments) ? document.comments : '';

    });
  }
    setToggleValues(initialCheckedValues);
    setReasonSelection(initialReasonSelection);
    setInputValues(initialInputValues);
    setReporters(result || []);
    setLoading(false);
  }

  useEffect(() => {
    if (!AuthService.isLoggedInUser()) {
      navigate(process.env.PUBLIC_URL + "/admin-login");
    } else {
      fetchData();
    }
  }, []);

  const formValidate = (event) => {
    return true;
  }


  const handleDocument = (documentType) => {
    let reason = '';
    let status = toggleValues[documentType] ? 'true' : 'false';
  
    if (!toggleValues[documentType] && reasonSelection[documentType] === 'Others') {
      reason = inputValues[documentType] || '';
    } else {
      reason = reasonSelection[documentType] || '';
    }
  
    setReasonSelection(prevState => ({
      ...prevState,
      [documentType]: ''
    }));
  
    setInputValues(prevState => ({
      ...prevState,
      [documentType]: ''
    }));
  
    return {
      [documentType]: status,
      [`${documentType}_reason`]: reason,
    };
  };

  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = await formValidate(event);
    if (isValid) {
        setBtnDisable(true);
        const formDataObj = new FormData();
        const updatedFormData = {
        ...handleDocument('aadhaar card'),
        ...handleDocument('bank_statement'),
        ...handleDocument('cancelled cheque'),
        ...handleDocument('contributor_form'),
        ...handleDocument('declaration_form'),
        ...handleDocument('gst_form'),
        ...handleDocument('pan card'),
        ...handleDocument('pan_aadhar_undertaking'),
        ...handleDocument('photograph'),
        ...handleDocument('resume'),
        ...handleDocument('signature'),
        // Add more field mappings as needed

      };
      updatedFormData.vendorId = id;
      const fieldMapping = {
      "aadhaar card": "aadhaar_card",
      "aadhaar card_reason": "aadhaar_card_reason",
      "cancelled cheque": "cancelled_cheque",
      "cancelled cheque_reason": "cancelled_cheque_reason",
      "contributor_form": "contributor",
      "contributor_form_reason": "contributor_reason",
      "declaration_form": "self_declaration",
      "declaration_form_reason": "self_declaration_reason",
      "gst_form": "gst_declaration",
      "gst_form_reason": "gst_declaration_reason",
      "pan card": "pan_card",
      "pan card_reason": "pan_card_reason",
      "pan_aadhar_undertaking": "pan_adhar",
      "pan_aadhar_undertaking_reason": "pan_adhar_reason",
      // Add more field mappings as needed
    };
    
    for (const oldField in fieldMapping) {
      if (fieldMapping.hasOwnProperty(oldField) && updatedFormData.hasOwnProperty(oldField)) {
        const newField = fieldMapping[oldField];
        updatedFormData[newField] = updatedFormData[oldField];
        delete updatedFormData[oldField];
      }
    }
    // console.log(updatedFormData, "new");
    async function saveAdminStatus() {
       let data = await AdminService.saveAdminStatus(updatedFormData);
      if (!data) {
         return false;
         }
        else{
         await new Promise(resolve => setTimeout(resolve, MINUTE_MS));
         navigate(`${process.env.PUBLIC_URL}/admin-all-listing`);
        }
      }
      await saveAdminStatus();
     fetchData();
    }
  };

  return (
    (loading) ? (<RotatingTriangles
      height="100"
      width="100"
      color="#fff"
      ariaLabel="rotating-triangels-loading"
      wrapperStyle={{}}
      wrapperClass="triangle-loading"
      visible={loading}
    />) : (
      <>
        <div className="page-wrapper">
          <Sidebar sidebarFor="Admin" />
          <div className="content-wrapper">
            <div className="page-content page-contributor-preview admin-reporter-details">
              <div className="page-header">
                <h4 className="section-title">Reporter Details</h4>
                <div className="breadcrumb-w">
                  <ol className="ms-breadcrumb">
                    <li><a href={process.env.PUBLIC_URL + `/admin-all-listing`} >Form- All</a></li>
                    <li className="active"> {reporters.length > 0 ? (
                      <>{reporters[0].vendor_info?.firstname || ""} {reporters[0].vendor_info?.lastname || ""}</>
                    ) : (
                      ""
                    )}</li>
                  </ol>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                    <section className="section-basic-details">
                      <div className="cap-wrapper">
                        <div className="form-caption">Personal Details</div>
                        <div className="preview-data">
                          <div className="pre-label">Name</div>
                          <div className="pre-value"><label className="input-label">{reporters?.[0]?.vendor_info?.firstname ?? ''} {reporters?.[0]?.vendor_info?.lastname ?? ''}</label></div>
                        </div>
                        <div className="preview-data">
                          <div className="pre-label">Gender</div>
                          <div className="pre-value">{reporters[0]?.vendor_info?.gender}
                          </div>
                        </div>
                        <div className="preview-data">
                          <div className="pre-label">DOB</div>
                          <div className="pre-value">{new Date(reporters[0]?.vendor_info?.dob).toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/\//g, '-') || ""}</div>
                        </div>
                        <div className="preview-data">
                          <div className="pre-label">Nationality</div>
                          <div className="pre-value">{reporters[0]?.vendor_info?.nationality || ""}</div>
                        </div>
                        <div className="preview-data">
                          <div className="pre-label">Marital Status</div>
                          <div className="pre-value">{reporters[0]?.vendor_info?.marital_status || ""}</div>
                        </div>
                      </div>
                      <div className="cap-wrapper">
                        <div className="form-caption">Family Details</div>
                        <div className="preview-data">
                          <div className="pre-label">Father's Name</div>
                          <div className="pre-value">{reporters[0]?.vendor_info?.f_firstname || ""} {reporters[0]?.vendor_info?.f_lastname || ""}</div>
                        </div>
                        <div className="preview-data">
                          <div className="pre-label">Mother's Name</div>
                          <div className="pre-value">{reporters[0]?.vendor_info?.m_firstname || ""} {reporters[0]?.vendor_info?.m_lastname || ""}</div>
                        </div>
                      </div>
                    </section>
                    <section className="section-address">
                      <div className="cap-wrapper">
                        <div className="form-caption">Present Address</div>
                        <div className="preview-data">
                          <div className="pre-value">
                            {reporters[0]?.vendor_address?.flat_no_present || ''}{' '}
                            {reporters[0]?.vendor_address?.landmark_present || ''}<br />
                            {reporters[0]?.vendor_address?.village_present || ''}{' '}
                            {reporters[0]?.vendor_address?.street_present || ''}<br />
                            {reporters[0]?.vendor_address?.locality_present || ''}{' '}
                            {reporters[0]?.vendor_address?.pincode_present || ''}<br />
                            {reporters[0]?.vendor_address?.city_present || ''}{' '}
                            {reporters[0]?.vendor_address?.state_present || ''}<br />
                            {reporters[0]?.vendor_address?.country_present || ''}<br />
                          </div>
                        </div>
                      </div>
                      <div className="cap-wrapper">
                        <div className="form-caption">Permanent Address</div>
                        <div className="preview-data">
                          <div className="pre-value"> {reporters[0]?.vendor_address?.flat_no_permanent || ''}{' '}
                            {reporters[0]?.vendor_address?.landmark_permanent || ''} <br />
                            {reporters[0]?.vendor_address?.village_permanent || ''}{' '}
                            {reporters[0]?.vendor_address?.street_permanent || ''}<br />
                            {reporters[0]?.vendor_address?.locality_permanent || ''}{' '}
                            {reporters[0]?.vendor_address?.pincode_permanent || ''}<br />
                            {reporters[0]?.vendor_address?.city_permanent || ''}{' '}
                            {reporters[0]?.vendor_address?.state_permanent || ''}<br />
                            {reporters[0]?.vendor_address?.country_permanent || ''}<br /></div>
                        </div>
                      </div>
                    </section>

                  <form className="contributor-form" onSubmit={handleSubmit}>
                     {preview.length > 0 && (
                      <section className="section-doc-upload">
                        <div className="cap-wrapper w-full">
                          <div className="form-caption">Documents Upload</div>
                          <div className="table-responsive">
                            <div className="admin-tbl-wrapper">
                              <table className="table admin-report-tbl">
                                <thead>
                                  <tr>
                                    <th width="220px">Proof Name</th>
                                    <th>Attach File</th>
                                  </tr>
                                </thead>
                                <tbody>
                            {preview.map((document) =>
                                  {
                                    if (document.document_type !== 'signature' && document.document_type !== 'photograph') {
                                      return (
                                    <tr key={document.id}>
                                      <td>
                                        <div className="tab-label v-mob">Proof Name</div>
                                        <div className="label text-upper">{document.document_type}<span className="asterisk">*</span></div>
                                      </td>
                                      <td>
                                        <div className="tab-label v-mob">Attach File</div>
                                        <div className="admin-ar-doc">
                                          <div className="attachment has-view-icon">
                                            <div className="attach-icon"><i className="fi fi-file-copy-black"></i></div>
                                            {document.document_path && (
                                              <>
                                                <div className="attachment-name">{document.document_path.substring(document.document_path.lastIndexOf('/') + 1).substring(37)}

                                                  <div className="attachment-action">
                                                    {document.document_path && document.document_path.endsWith(".pdf") ? (
                                                      <a href={document.document_path} className="download-icon" download ><i className="fi fi-file-download"></i>
                                                      </a>) : (<a className="download-icon"><i className="fi fi-file-download" onClick={() => handleDownload(document.document_path)}></i></a>
                                                    )}
                                                    <div className="view-icon">
                                                      <i onClick={() => {
                                                        if (document.document_path && document.document_path.endsWith('.pdf')) {
                                                          // Open the PDF file using a PDF viewer
                                                          viewPDF(document.document_path, 'application/pdf');
                                                        } else {
                                                          // Handle other file types
                                                          onOpenModal(document.document_path);
                                                        }
                                                      }} className="fi fi-visibility-black"></i>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                          
                                          <div className="ar-toggler">
                                            <label className="check-w">
                                              <input
                                                type="checkbox"
                                                onChange={(e) => {
                                                  gstDeclarationToggler(e, document.document_type);
                                                  if (e.target.checked) {
                                                    setErrors(prevErrors => ({
                                                      ...prevErrors,
                                                      [document.document_type]: ''
                                                    }));
                                                    const selectedObject = document.document_type;
                                                    const updatedErrorsinput = { ...errorsinput };
                                                    updatedErrorsinput[selectedObject] = ''; // Clear the error message for the specific object
                                                    setErrorsinput(updatedErrorsinput);
                                                      // Clear the input values
                                                    const selectedInputValue = document.document_type;
                                                    const updatedInputValues = { ...inputValues };
                                                    updatedInputValues[selectedInputValue] = ''; // Clear the input value for the specific object
                                                    setInputValues(updatedInputValues);

                                                  }
                                                }}
                                                name={`accept_gst_declaration_${document.document_type}`}
                                                id={`accept_gst_declaration_${document.document_type}`}
                                                className="checkbox"
                                                // defaultChecked={document.status !== "Processing"} // Set defaultChecked based on document.status
                                                // checked={document.status === "Accepted"} // Toggle based on document.status
                                                // checked={document.status === 'Accepted'} // Toggle based on document.status
                                                defaultChecked={toggleValues[document.document_type]}

                                              />
                                                <div id={`gst_declaration_toggler_${document.document_type}`} className="checkbox-toggler">
                                                <div className="checked">Accept</div>
                                                <div className="unchecked">Reject</div>
                                                </div>
                                            </label>
                                          </div>



                                          <div className="ar-sel-reason">
                                            <div className="formGroup">
                                              <select
                                                onInvalid={(e) => {
                                                  const selectedObject = document.document_type;
                                                  const updatedErrors = { ...errors, [selectedObject]: 'Select rejected reason' };
                                                  setErrors(updatedErrors);
                                                }}
                                                required
                                                disabled={document.status === 'Accepted'}
                                                name={`file_gst_declaration_reason_${document.document_type}`}
                                                id={`file_gst_declaration_reason_${document.document_type}`}
                                                className="input-control"
                                                onChange={(e) => {
                                                  const selectedObject = document.document_type;
                                                  const selectedReason = e.target.value;
                                                  const updatedErrors = { ...errors };
                                                  updatedErrors[selectedObject] = ''; // Clear the error message for the specific object
                                                  setErrors(updatedErrors);
                                                  setReasonSelection(prevState => ({
                                                    ...prevState,
                                                    [selectedObject]: selectedReason
                                                  }));
                                                }}
                                                value={
                                                  reasonSelection[document.document_type]
                                                }
                                                
                                              >
                                                <option value="">Select</option>
                                                <option value="Quality poor">Quality poor</option>
                                                <option value="Offensive content">Offensive content</option>
                                                <option value="Image not appropriate">Image not appropriate</option>
                                                <option value="Others">Others</option>
                                              </select>
                                            </div>
                                            <label className="error">{errors[document.document_type] ? errors[document.document_type] : ""}</label>
                                          </div>

                                          <div className="ar-other-reason">
                                            <div className="formGroup">
                                              <input
                                                onInvalid={(e) => {
                                                  const selectedObject = document.document_type;
                                                  if (reasonSelection[selectedObject] === 'Others') {
                                                    const updatedErrors = { ...errorsinput, [selectedObject]: 'Please enter reason' };
                                                    setErrorsinput(updatedErrors);
                                                  }
                                                }}
                                                onChange={(e) => {
                                                  const selectedObject = document.document_type;
                                                  const updatedErrors = { ...errorsinput };
                                                  updatedErrors[selectedObject] = ''; // Clear the error message for the specific object
                                                  setErrorsinput(updatedErrors);
                                                  // Additional logic if needed

                                                  const selectedValue = e.target.value;
                                                  setInputValues(prevInputValues => ({
                                                    ...prevInputValues,
                                                    [selectedObject]: selectedValue
                                                  }));
                                                }}

                                                required
                                                disabled={document.status === 'Accepted' || (reasonSelection[document.document_type] !== 'Others')}
                                                type="text"
                                                className="input-control"
                                                placeholder="Enter Reason"
                                                name={`file_gst_declaration_comments_${document.document_type}`} // Dynamic name attribute
                                                id={`file_gst_declaration_comments_${document.document_type}`} // Dynamic id attribute
    
                                                value={inputValues[document.document_type] || ''}



                                              />

                                              <label className="error">{errorsinput[document.document_type] ? errorsinput[document.document_type] : ""}</label>
                                            </div>
                                          </div>
                                      </div>
                                      </td>
                                    </tr>
                                  );
                                }
                                return null;
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                </div>
                      </section>
             )}

            {preview.length > 0 && preview.some((document) => document.document_type === 'signature' || document.document_type === 'photograph') ? (
              <section className="section-photo-signature">
                <div className="cap-wrapper w-full">
                  <div className="form-caption">Photo and Signature</div>
                  <div className="ps-wrapper">
                  {preview.map((document) => {
                        if (document.document_type === 'photograph' || document.document_type === 'signature') {
                          return (
                            <div className="cap-wrapper">
                                      <div className="image-preview-data" key={document.id}>
                                                    <div className="label">{document.document_type}</div>
                                                    <div className="image-preview">
                                                      <img src={document.document_path} alt={document.document_name} />
                                                      <div className="download-action">
                                                        <a  className="download-icon">
                                                          <i className="fi fi-file-download" onClick={() => handleDownload(document.document_path)}></i>
                                                        </a>
                                                      </div> 
                                                    </div>
                                                    <div className="admin-ar-ps">
                                                      <div className="ar-toggler">
                                                        <label className="check-w">
                                                          <input
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                              gstDeclarationToggler(e, document.document_type);
                                                              if (e.target.checked) {
                                                                setErrors(prevErrors => ({
                                                                  ...prevErrors,
                                                                  [document.document_type]: ''
                                                                }));
                                                                const selectedObject = document.document_type;
                                                                const updatedErrorsinput = { ...errorsinput };
                                                                updatedErrorsinput[selectedObject] = ''; // Clear the error message for the specific object
                                                                setErrorsinput(updatedErrorsinput);
                                                                  // Clear the input values
                                                                const selectedInputValue = document.document_type;
                                                                const updatedInputValues = { ...inputValues };
                                                                updatedInputValues[selectedInputValue] = ''; // Clear the input value for the specific object
                                                                setInputValues(updatedInputValues);

                                                              }
                                                            }}
                                                            name={`accept_gst_declaration_${document.document_type}`}
                                                            id={`accept_gst_declaration_${document.document_type}`}
                                                            className="checkbox"
                                                            // defaultChecked={document.status !== "Processing"} // Set defaultChecked based on document.status
                                                            // checked={document.status === "Accepted"} // Toggle based on document.status
                                                            // checked={document.status === 'Accepted'} // Toggle based on document.status
                                                            defaultChecked={toggleValues[document.document_type]}

                                                          />
                                                            <div id={`gst_declaration_toggler_${document.document_type}`} className="checkbox-toggler">
                                                            <div className="checked">Accept</div>
                                                            <div className="unchecked">Reject</div>
                                                            </div>
                                                        </label>
                                                      </div>
                                                      


                                                      <div className="ar-sel-reason">
                                                        <div className="formGroup">
                                                          <select
                                                            onInvalid={(e) => {
                                                              const selectedObject = document.document_type;
                                                              const updatedErrors = { ...errors, [selectedObject]: 'Select rejected reason' };
                                                              setErrors(updatedErrors);
                                                            }}
                                                            required
                                                            disabled={document.status === 'Accepted'}
                                                            name={`file_gst_declaration_reason_${document.document_type}`}
                                                            id={`file_gst_declaration_reason_${document.document_type}`}
                                                            className="input-control"
                                                            onChange={(e) => {
                                                              const selectedObject = document.document_type;
                                                              const selectedReason = e.target.value;
                                                              const updatedErrors = { ...errors };
                                                              updatedErrors[selectedObject] = ''; // Clear the error message for the specific object
                                                              setErrors(updatedErrors);
                                                              setReasonSelection(prevState => ({
                                                                ...prevState,
                                                                [selectedObject]: selectedReason
                                                              }));
                                                            }}
                                                            value={
                                                              reasonSelection[document.document_type]
                                                            }
                                                            
                                                          >
                                                            <option value="">Select</option>
                                                            <option value="Quality poor">Quality poor</option>
                                                            <option value="Offensive content">Offensive content</option>
                                                            <option value="Image not appropriate">Image not appropriate</option>
                                                            <option value="Others">Others</option>
                                                          </select>
                                                        </div>
                                                        <label className="error">{errors[document.document_type] ? errors[document.document_type] : ""}</label>
                                                      </div>

                                                      <div className="ar-other-reason">
                                                        <div className="formGroup">
                                                          <input
                                                            onInvalid={(e) => {
                                                              const selectedObject = document.document_type;
                                                              if (reasonSelection[selectedObject] === 'Others') {
                                                                const updatedErrors = { ...errorsinput, [selectedObject]: 'Please enter reason' };
                                                                setErrorsinput(updatedErrors);
                                                              }
                                                            }}
                                                            onChange={(e) => {
                                                              const selectedObject = document.document_type;
                                                              const updatedErrors = { ...errorsinput };
                                                              updatedErrors[selectedObject] = ''; // Clear the error message for the specific object
                                                              setErrorsinput(updatedErrors);
                                                              // Additional logic if needed

                                                              const selectedValue = e.target.value;
                                                              setInputValues(prevInputValues => ({
                                                                ...prevInputValues,
                                                                [selectedObject]: selectedValue
                                                              }));
                                                            }}

                                                            required
                                                            disabled={document.status === 'Accepted' || (reasonSelection[document.document_type] !== 'Others')}
                                                            type="text"
                                                            className="input-control"
                                                            placeholder="Enter Reason"
                                                            name={`file_gst_declaration_comments_${document.document_type}`} // Dynamic name attribute
                                                            id={`file_gst_declaration_comments_${document.document_type}`} // Dynamic id attribute
                
                                                            value={inputValues[document.document_type] || ''}



                                                          />

                                                          <label className="error">{errorsinput[document.document_type] ? errorsinput[document.document_type] : ""}</label>
                                                        </div>
                                                      </div>
                                                      </div>
                            </div>
                        
                    </div>
                    );
                  }
                  return null;
                })}
                    
                  </div>
                </div>
              </section>
            ) : (
            <section className="section-photo-signature">
                <div className="cap-wrapper w-full">
                  <div className="form-caption">Photo and Signature</div>
                  <div className="ps-wrapper">
                    <div className="cap-wrapper">
                      {preview.map((document) => {
                        if (document.document_type === 'signature') {
                        
                        } 
                        return null;
                      })}
                    </div>
                    <div className="cap-wrapper">
                      {preview.map((document) => {
                        if (document.document_type === 'photograph') {
                          
                        }
                        return null;
                      })}
                    </div>
                  </div>
                </div>
            </section>
              )}

                   



                    <div className="form-action">
                      {(btn_disable) ? (<ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#2A426E', '#2A426E', '#2A426E', '#2A426E', '#2A426E']}
                      />) : (<button className="submit-btn btn-solid" type="submit">Save</button>)}
                    </div>
                  </form>

                </div>

              </div>
            </div>

          </div>
        </div >
        <Modal open={open} onClose={onCloseModal} center>
          <img style={{ width: "400px", height: "auto" }} src={modalFilename} alt="show image" />
        </Modal>
      </>
    )
  )
}
export default AdminReportersList2;